import referralClient from "@/helpers/referralClient";

export default {
    data() {
        return {
            surgeries: [],
            selectedSurgery: null,
            isSurgeriesLoading: false,
        }
    },
    methods: {
        asyncFindSurgeries(query) {
            this.isSurgeriesLoading = true;

            if (query.length < 2) {
                this.surgeries = [];
                this.isSurgeriesLoading = false;
                return;
            }

            referralClient.get(`/Reference/search/gp?q=${query}`).then(response => {
                this.surgeries = response.data;
                this.isSurgeriesLoading = false
            })
                .catch(() => {
                    this.surgeries = []
                    this.isSurgeriesLoading = false;
                })
        },
        clearAll() {
            this.selectedSurgery = '';
        }
    }
}
