import axios from 'axios'
import store from "@/store";
import apiClient from "@/helpers/apiClient";

export const referralClient = axios.create({
    baseURL: process.env.VUE_APP_REFERRAL_API_URL
})

referralClient.interceptors.request.use((config) => {
    let apiToken = store.getters['Auth/getTokens'].accessToken;
    if (apiToken) {
        config.headers.Authorization = `Bearer ${apiToken}`
    }
    return config
}, (err) => {
    return Promise.reject(err)
});


export default referralClient;
