import referralClient from "@/helpers/referralClient";

export default {
    data() {
        return {
            medications: [],
            selectedMedications: [],
            isMedicationsLoading: false,
        }
    },
    methods: {
        asyncFindMedications(query) {
            this.isMedicationsLoading = true;

            if (query.length < 2) {
                this.medications = [];
                this.isMedicationsLoading = false;
                return;
            }

            referralClient.get(`/Reference/search/medications?q=${query}`).then(response => {
                this.medications = response.data;
                this.isMedicationsLoading = false
            })
                .catch(() => {
                    this.medications = []
                    this.isMedicationsLoading = false;
                })
        },
        clearAll() {
            this.selectedMedications = []
        }
    }
}
