import referralClient from "@/helpers/referralClient";

export default {
    data() {
        return {
            conditions: [],
            selectedConditions: [],
            isConditionsLoading: false,
        }
    },
    methods: {
        asyncFindConditions(query) {
            this.isConditionsLoading = true;

            if (query.length < 2) {
                this.conditions = [];
                this.isConditionsLoading = false;
                return;
            }

            referralClient.get(`/Reference/search/conditions?q=${query}`).then(response => {
                this.conditions = response.data;
                this.isConditionsLoading = false
            })
                .catch(() => {
                    this.conditions = []
                    this.isConditionsLoading = false;
                })
        },
        clearAll() {
            this.selectedConditions = []
        }
    }
}
