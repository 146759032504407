<template>
  <div>
    <gro-popper :config="{placement: 'right-start',  modifiers: {
            preventOverflow: {
              enabled: true,
              padding: 20
            },
            offset: {
              offset: '0, 16',
            }
          }}" style="left: 16px; top: 16px">
      <gro-outside-click slot-scope="{isOpen, open, close, update}" :do="close">
        <div>
          <div data-trigger @click="update" class="d-inline">
            <div class="d-flex">
              <gro-fab-button @click.native="open">
                <i class="icon icon-settings-grey" style="vertical-align: middle;"></i>
              </gro-fab-button>
            </div>
          </div>
          <div v-show="isOpen" data-popper class="gro__calendar__popper" style="z-index: 999;width: 260px;">
            <form @submit.prevent="onSubmitUnitPrefs(close)">
              <gro-card>
                <div slot="body">
                  <h3>Unit preferences</h3>

                  <div class="gro__select gro__input mb-3">
                    <label for="txt_weight_units" class="gro__input__label" :class="{'has_error': $v.unitPreferences.weight.$error}">Weight units</label>
                    <select id="txt_weight_units" v-model="unitPreferences.weight"
                            class="gro__select__native__element gro__input__element">
                      <option :value="null" label="-- Please select --"></option>
                      <option value="stlbs" label="Stones & pounds"></option>
                      <option value="kg" label="Kilograms"></option>
                    </select>
                  </div>

                  <div class="gro__select gro__input mb-4">
                    <label for="txt_height_units" class="gro__input__label" :class="{'has_error': $v.unitPreferences.height.$error}">Height units</label>
                    <select id="txt_height_units" v-model="unitPreferences.height"
                            class="gro__select__native__element gro__input__element">
                      <option :value="null" label="-- Please select --"></option>
                      <option value="ftin" label="Feet & inches"></option>
                      <option value="cm" label="Centimetres"></option>
                    </select>
                  </div>


                  <gro-button
                      class="mb-2 w-100"
                      loading-title="Processing..."
                      style="position: relative; z-index: 0"
                      type="submit"
                      size="md"
                      gradient
                      glowing
                      centered>
                    Save
                    <i class="icon-right-arrow relative font-size-16"></i>
                  </gro-button>
                </div>
              </gro-card>
            </form>
          </div>
        </div>
      </gro-outside-click>
    </gro-popper>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from 'vuelidate/lib/validators'
export default {
  name: "UnitPreferences",
  mixins: [validationMixin],
  validations: {
    unitPreferences: {
      weight: {
        required,
      },
      height: {
        required,
      }
    }
  },
  data() {
    return {
      unitPreferences: {
        weight: '',
        height: '',
      },
    }
  },
  methods: {
    onSubmitUnitPrefs(close) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit('User/setUserPreferences', this.unitPreferences);
      close();
    },
  },
  mounted() {
    this.unitPreferences.height = this.$store.getters['User/getUserPreferences'].height;
    this.unitPreferences.weight = this.$store.getters['User/getUserPreferences'].weight;
  }
}
</script>

<style scoped>

</style>